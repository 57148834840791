<template>
  <el-dialog v-model="specializedShow" title="缴费详情" width="30%">
    <el-form
      label-width="100px"
      :model="studentForm"
      :rules="studentFormRules"
      ref="studentdRef"
    >
      <el-form-item label="订单号" prop="orderid">
        <el-input v-model="studentForm.orderid" disabled />
      </el-form-item>
      <el-form-item label="支付金额" prop="amount">
        <el-input v-model="studentForm.amount" disabled />
      </el-form-item>
      <el-form-item label="支付状态" prop="trade_state">
        <el-input v-model="computedTradeStateText" disabled />
      </el-form-item>
      <el-form-item label="支付时间" prop="success_time">
        <el-input v-model="studentForm.success_time" disabled />
      </el-form-item>
      <el-form-item label="	交易类型" prop="trade_type">
        <el-input v-model="studentForm.trade_type" disabled />
      </el-form-item>

      <el-form-item label="交易描述" prop="trade_state_desc">
        <el-input v-model="studentForm.trade_state_desc" disabled />
      </el-form-item>

      <el-form-item label="创建时间" prop="created_at">
        <el-input v-model="studentForm.created_at" disabled />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeBtn">取消 </el-button>
        <!-- <el-button type="primary" @click="onOkBtn">确认</el-button> -->
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import { infirmaryInfoAPI } from '@/api/finance'
/**
 *  props S
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  studentID: {
    type: Number
  },

  onListOldStudent: {
    type: Function
  }
})

defineEmits(['update:modelValue']) // 注册 v-model 事件
const specializedShow = useVModel(props)

watch(
  () => props.studentID,
  (val) => {
    onStudentDetail(val)
  },
  {
    deep: true
  }
)

const computedTradeStateText = computed(() => {
  if (studentForm.value.trade_state === '0') {
    return '未支付'
  } else if (studentForm.value.trade_state === '1') {
    return '已支付'
  } else {
    return '其他状态'
  }
})
const onStudentDetail = async (id) => {
  const data = await infirmaryInfoAPI({ id })
  studentForm.value = data
}

/** props E */

/** 表单 S */
// From 实例
const studentdRef = ref(null)
const studentForm = ref({
  id: '',
  orderid: '', //  订单号
  trade_type: '', //  交易类型
  trade_state: '', // 支付状态
  trade_state_desc: '', // 交易描述
  success_time: '', //  支付时间
  amount: '', //  支付金额
  created_at: '' // 创建时间
})

// 验证规则
const studentFormRules = ref({})

/** 表单 E */

const closeBtn = () => {
  specializedShow.value = false
}
</script>

<style></style>
